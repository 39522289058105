.error-section .row {
  justify-content: center;
  text-align: center;
}

.error-wrap .img-wrap img {
  width: auto;
}

.error-wrap .content .title {
  font-size: 36px;
}

.error-wrap .content p {
  margin: 30px 0;
}

.title-product {
  color: rgb(167, 166, 166);
  font-size: 36px;
  margin-bottom: 25px;
  margin-top: 35px;
}

.error-section{
  padding: 96px 0;
}

