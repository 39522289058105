/**********************************
 banner
 ***********************************/
.cl-banner {
  /* background: url("../../../../public/images/bg-image.png") no-repeat; */
  /* background: url("../../../../public/images/bg-image.png") no-repeat;
  background-color: #e1e6e866; */
  background-position: center;
  /* background-size: cover; */
}

.cl-banner .banner-content {
  border-radius: 5px;
  height: 100%;
}

.cl-banner .banner-content p {
  font-size: 18px;
}

.carousel-indicators [data-bs-target] {
  background-color: white;
  border-radius: 100%;
  height: 9px;
  width: 9px;
}

.carousel-indicators .active {
  background-color: white;
  height: 12px;
  width: 12px;
}

.cl-header {
  position: relative;
  /* background-color: black; */
}

.sub-title {
  position: absolute;
  color: #fff;
  top: 0;
  padding-left: 40px;
  padding-top: 45px;
  z-index: 2;
  max-width: 70%;
}

.sub-title .subtitle,
.sub-title .main-title {
  color: #fff;
}

.sub-title .subtitle {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
}

.sub-title .main-title {
  font-size: 40px;
  font-weight: 400;
  margin: 15px 0;
}

.sub-title a {
  color: #fff;
}

.sub-title p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
}

.sub-title .cl-button {
  background-color: white;
  color: black;
  font-weight: 700;
  border: 1px solid white;
}

.main-title span {
  font-weight: 700;
}

/* .owl-carousel .owl-item img {
  display: block;
  width: 91px;
  height: 90px;
} */
/* .sub-title h1 {
  margin-top: 10px;
  font-size: 40px;
  font-weight: 600;
} */
/* .sub-title p {
  margin-top: 10px;
} */
/* .cl-button {
  background-color: var(--cl-bg-color);
  color: black;
} */
.carousel-item,
.carousel-image {
  height: 422px;
}

.carousel-image img {
  height: 350px;
  /* max-width: 700px; */
  object-fit: cover;
  z-index: 1;
  border-radius: 5px;
  height: 100%;
  /* height: 422px; */
}

.carousel-control-next,
.carousel-control-prev {
  display: none;
}

.carousel-image {
  position: relative;
  z-index: 1;
  color: #fff;
}

.carousel-image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(#000, rgba(0, 0, 0, 0)); */
  background: linear-gradient(to left, rgb(0 0 0 / 18%), #000000c4);
  z-index: 1;
  border-radius: 5px;
}

.banner-carousel {
  /* background-color: #D9D9D9; */
  background: linear-gradient(to left, #c93f0f, #f58d69);
  border-radius: 5px;
  z-index: 10;
  position: relative;
  color: white;
}

.banner-carousel img {
  width: 210px;
}

.banner-carousel {
  height: 422px;
}

.banner-carousel p,
.banner-carousel h2 {
  margin-bottom: 0;
}

.organic-product {
  text-align: right;
  padding-right: 37px;
  padding-top: 60px;
  /* width: 75%; */
  margin-left: auto;
  padding-bottom: 50px;
}

.banner-carousel .img-wrap {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  z-index: -10;
}

.banner-carousel h2 {
  font-size: 36px;
  font-weight: 700;
  color: white;
}

.organic-product p {
  margin-top: 16px;
}

.organic-icon {
  color: white;
  margin-top: 16px;
  display: block;
}

.organic-icon:hover {
  color: lightsalmon;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.sub-title .cl-button:hover {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.banner-section {
  padding-top: 48px;
}

.product-icon-shop {
  margin-left: 10px;
  margin-top: -1px;
}

.organic-icon:hover .product-icon-shop {
  margin-left: 5px;
  transition: all ease-in-out 0.4s;
}

@media screen and (max-width: 1199px) {
  .sub-title p {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 991px) {
  .sub-title {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .banner-carousel {
    margin-top: 30px;
  }

  .sub-title p {
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 576px) {
  .banner-section {
    padding-top: 35px;
  }

  .carousel-image img {
    object-fit: contain;
  }

  .sub-title .main-title {
    font-size: 32px;
  }

  .cl-banner .banner-content p {
    font-size: 16px;
  }

  .sub-title .subtitle {
    font-size: 14px;
  }

  .banner-carousel h2 {
    font-size: 32px;
  }

  .organic-product p {
    margin-top: 12px;
  }

  .sub-title {
    padding-left: 30px;
    max-width: 100%;
    padding-bottom: 30px;
  }

  .sub-title .cl-button {
    background-color: #fff;
    color: black;
    font-weight: 700;
  }
  .sub-title a {
    color: #fff;
  }

  .sub-title .cl-button {
    font-size: 14px;
    padding: 8px 12px;
  }

  .carousel-item {
    height: 400px;
  }

  .loader-container {
    margin-top: 0;
  }
}

@media screen and (max-width: 367px) {
  .sub-title p {
    margin-bottom: 24px;
  }
}
