.popup-container-profile.modal-content {
  margin-top: 50%;
}

.popup-sav-change {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
