* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.error-section .row {
  justify-content: center;
  text-align: center;
}

.submit-wrap .img-submit img {
  width: auto;
  margin: 0 auto 24px;
  display: block;
}

.submit-wrap .enquire-content .title {
  font-size: 36px;
}

.submit-wrap .enquire-content p {
  margin: 30px 0;
}

.submit-section .row {
  justify-content: center;
  align-items: center;
}

.enquire-content {
  text-align: center;
}

.submit-wrap .enquire-content .title {
  font-size: 36px;
}

.submit-wrap .enquire-content p {
  margin: 8px 0;
  margin-bottom: 32px;
}

.gray-button {
  background-color: #e5e5e5;
  color: black;
}

.icon-left {
  font-size: 20px;
  margin-top: -4px;
  margin-left: -7px;
  margin-right: 8px;
  color: gray;
}

.submit-section .cl-button:hover {
  color: #1a68b2;
}

.submit-section .icon-left:hover {
  color: #1a68b2;
}

.submit-row.row {
 --bs-gutter-x: -1.5rem;
}

.submit-row {
  margin-bottom: 96px;
}

@media (max-width: 1199px) {
  .submit-row .row {
    --bs-gutter-x: 1.5rem;
  }
}


@media screen and (max-width: 768px) {
  .submit-section .row {
    gap: 35px;
  }

  .submit-row {
    margin-bottom: 75px;
  }
  
 .submit-row .row {
    --bs-gutter-x: -0.5rem;
  }
}
