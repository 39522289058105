.custom-breadcrumbs {
  background: url("../../../public/images/breadcurmb-banner.png") no-repeat;
  background-size: cover;
  background-position: center;
  padding: 45px 0;
  position: relative;
  text-align: center;
}

.custom-breadcrumbs .cl-header {
  margin-bottom: 0;
}
