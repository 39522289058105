/**********************************
 top header
 ***********************************/
/* .top-content {
  /* margin-bottom: 26px;
  padding: 15px 0;
}  */
.top-header {
  border-bottom: 1px solid rgba(232, 240, 247, 1);
  padding: 26px 0;
}

.top-header .form-group {
  background-color: rgba(247, 247, 247, 1);
  width: 30%;
}

.top-header .form-group .form-control {
  background-color: var(--cl-bg-color);
  border-color: transparent;
  border-radius: 4px 0 0 4px;
  color: #999999;
  font-size: 14px;
  padding: 12px 15px;
}

.top-header .form-group .form-control:focus {
  color: var(--cl-text-color);
  border-color: var(--cl-primary-color);
  /* transform: scale(1.05); 
  transition: transform 0.3s ease;*/
}

.top-header .icon-wrap {
  background-color: var(--cl-bg-color);
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  padding: 12px 15px;
  text-align: center;
}

.top-header .icon-inline li {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.top-header .icon-inline li:last-child {
  margin-right: 0;
}

.top-header .icon-inline li .icon {
  font-size: 30px;
}

.top-header .icon-inline li {
  margin-right: 25px;
}

.top-header .icon-inline li:last-child {
  margin-right: 0;
}

.top-header .icon-inline li.custom-badge .icon,
.top-header .icon-inline li.user-profile .icon,
.top-header .icon-inline li.user-ac .icon {
  font-size: 16px;
}

.top-header .icon-inline li .badge {
  background: #e8f0f7;
  border-radius: 100%;
  color: var(--cl-primary-color);
  font-size: 11px;
  position: absolute;
  right: -14px;
  top: -4px;
}

.custom-dropdown .dropdown-menu {
  border: none;
  border-radius: 0;
  box-shadow: 0 0px 5px rgb(0 0 0 / 14%);
  padding: 0;
  position: absolute;
  top: auto;
  display: none;
  right: 0;
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
}

.custom-dropdown .dropdown-menu li {
  display: block;
  font-size: 14px;
  margin-right: 0;
}

.custom-dropdown .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #efefef;
  display: block;
  padding: 7px 15px;
}

.custom-dropdown .dropdown-menu li:last-child .dropdown-item {
  border-bottom: none;
}

.custom-dropdown .dropdown-menu .dropdown-item:hover {
  padding-left: 10px;
}
/**********************************
 navigation
 ***********************************/

.cl-navigation .navbar-nav .nav-link,
.cl-navigation .nav-link {
  color: black;
  padding: 12px 18px;
}

.cl-navigation .login-wrap li {
  display: inline-block;
}

.cl-navigation .navbar-toggler {
  align-items: center;
  border-radius: 3px;
  height: 35px;
  justify-content: center;
  line-height: 35px;
  padding: 0px 5px;
  width: 35px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.custom-dropdown .dropdown-item {
  background-color: rgb(225 225 225);
}

.top-header .cl-button {
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.user-icon .custom-badge .icon {
  font-size: 17px;
}

.user-icon .custom-badge .user-icon {
  font-size: 17px;
}

.login-link {
  color: white;
}

.login-link a:hover {
  color: none;
}

button .nav-link {
  color: white;
  font-size: 14px;
}

.hello {
  display: flex;
}

.top-header .cl-button:hover {
  color: var(--cl-primary-color);
}

.navbar-toggler-icon {
  color: black !important;
}

@media screen and (max-width: 1199px) {
  .cl-navigation .navbar-nav .nav-link,
  .cl-navigation .nav-link {
    padding: 15px;
  }

  .top-header .form-group {
    width: 25%;
  }

  .top-header .icon-inline li.custom-badge {
    margin-right: 22px;
  }

  .dropdown-menu {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    gap: 15px;
  }
}

@media screen and (max-width: 991.5px) {
  .cl-navigation .navbar-collapse {
    background: var(--cl-primary-color);
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    right: -250px;
    left: auto;
    opacity: 0;
    top: 0;
    transition: all ease-in-out 0.4s;
    visibility: hidden;
    width: 250px;
    z-index: 99;
  }

  .cl-navigation .navbar-collapse.show {
    visibility: visible;
    opacity: 1;
    left: 0;
    transition: all ease-in-out 0.4s;
  }

  .cl-navigation .navbar-nav .navbar-collapse.show .nav-link,
  .cl-navigation .navbar-collapse.show .nav-link {
    border-bottom: 1px solid #517fab;
    color: rgb(208, 206, 206);
  }

  .cl-navigation .navbar-toggler {
    display: flex;
  }

  .cl-navigation .navbar-nav {
    border-bottom: 1px dotted #e7e7e7;
    display: block;
  }

  .cl-navigation .navbar-nav:last-child {
    border-bottom: none;
  }

  .top-header .custom-badge .icon {
    margin-top: 0px;
  }

  .top-header .icon-inline li .badge {
    top: -2px;
  }

  .top-header .form-group {
    width: 45%;
  }

  .navbar-toggle {
    position: absolute;
  }

  .dim-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }

  .dimmed {
    display: block;
  }

  .cl-navigation {
    z-index: 999;
  }

  .logged-in-margin {
    margin-left: -21px;
    width: 133px;
  }

  .not-logged-in-margin {
    margin-left: -4px;
    width: 116px;
  }
}

@media screen and (max-width: 767px) {
  .top-header .custom-badge .icon {
    margin-top: 2px;
  }

  .top-header .icon-inline li .badge {
    top: -2px;
  }

  .top-header .top-content {
    position: relative;
    padding-bottom: 74px;
    justify-content: unset !important;
  }

  .top-header .nav-brand {
    margin-right: auto;
  }

  .top-header .form-group {
    width: 100%;
    position: absolute;
    bottom: 8px;
  }

  .top-header .form-group {
    width: 100%;
  }

  .logged-in-margin {
    margin-left: 16px;
    width: 106px;
  }

  .not-logged-in-margin {
    margin-left: 16px;
    width: 106px;
  }
}

@media screen and (max-width: 576px) {
  .top-header .form-group {
    width: 100%;
    visibility: hidden;
  }

  .top-header .icon-inline li.user-ac .user-icon {
    margin-top: -10px;
  }

  /* .top-header .form-group {
    width: 100%;
  } */

  .top-header .icon-inline {
    /* position: absolute; */
    /* bottom: 10px;
    right: 10px;
    top: 77px; */
  }

  .top-header .icon-inline li.custom-badge {
    /* position: absolute; */
    /* top: -59px;
    /* top: -75px; 
    right: 41px; */
  }

  .user-ac.dropdown.custom-dropdown {
    height: 42px;
    line-height: 42px;
  }

  .top-header .top-content {
    position: relative;
    padding-bottom: 0px;
    justify-content: unset !important;
  }
}

@media screen and (max-width: 400px) {
  .top-header .cl-button {
    padding: 10px 12px;
  }
}

@media screen and (max-width: 320px) {
  .top-header .top-content .icon-inline .btn {
    padding: 4px 10px;
  }

  .top-header .top-content .icon-inline .btn .nav-link {
    font-size: 12px;
  }
}
