* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* .modal-content {
  width: 708px;
  margin-left: -97px;
} */
.popup-container {
  position: absolute; 
  top: 50%;
  left: 5%0;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;


  /* color: white; */
}

.popup-row {
  padding: 0px 15px;
}
.popup-col {
  margin-top: 20px;
}
.popup-h6 {
  margin-top: 35px;
  font-size: 18px;
}
.popup-row label {
  color: #111;
}
.popup-row input {
  font-size: 14px;
}
.modal-header .btn-close {
  display: none;
}
.icon-popup {
  font-size: 25px;
  color: gray;
}

.scrollable-modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
/* 
.scrollable-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 410px;
  overflow-y: auto;
} */

/* .modal-dialog {
  margin-top:33.5px ;
} */

.popup-container .btn-singleenquiry:hover {
  background-color: black;
}
