.button-btn-reset {
  margin-bottom: 96px;
  background-color: #1a68b2;
  border: 1px solid #1a68b2;
}

.button-btn-reset:hover {
  background-color: transparent;
  color: #1a68b2;
  transition: all ease-in-out 0.4s;
}
