.owl-theme .owl-nav [class*="owl-"] {
  background-color: #f3f3f3 !important;
  color: var(--cl-text-color) !important;
  height: 35px;
  line-height: 30px !important;
  width: 35px;
}

.top-brand-header .owl-nav {
  position: absolute;
  top: -85px;
  right: 0;
  margin: 0;
}

.owl-theme .owl-nav [class*="owl-"] span {
  font-size: 36px;
}

.brand-carousel .brand-wrap,
.allbrand-section .brand-wrap {
  align-items: center;
  background-color: var(--cl-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  position: relative;
}

.allbrand-section .brand-wrap {
  margin-bottom: 25px;
}

.brand-carousel .brand-wrap .title,
.allbrand-section .brand-wrap .title {
  bottom: 20px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}

.loader-displayedItems {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.brand-link {
  margin-top: 24px;
  display: block;
}

.banner-carousel .img-wrap {
  height: 210px;
}

.brand-carousel .owl-item img,
.allbrand-section .brand-wrap .img-wrap img {
  display: block;
  width: 128px;
  /* height: 50px;
  object-fit: cover; */
  width: 128px;
  height: 76px;
  object-fit: contain;
}

.brand-carousel .owl-item img,
.allbrand-section .brand-wrap .img-wrap img {
  transition: transform ease-in-out 0.4s;
}

.brand-carousel .brand-wrap:hover .img-wrap img,
.brand-carousel .brand-wrap.touch-zoom .img-wrap img {
  transform: scale(0.85);
}

.allbrand-section .brand-wrap:hover .img-wrap img,
.allbrand-section .brand-wrap.touch-zoom .img-wrap img {
  transform: scale(0.85);
}

.brand-carousel .item{
  cursor: pointer;
}

.brand-carousel .title{
  cursor: pointer;
}
@media screen and (max-width: 1199px) {
  .brand-wrap .title {
    bottom: 8px;
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .brand-carousel .brand-wrap .title {
    bottom: 16px;
  }
}
/* .brand-carousel .owl-item img {
  display: block;
  width: 96%;
  height: 113px;
} */

@media screen and (max-width: 767px) {
  /* .top-brand-header .owl-nav {
    top: 96%;
    right: auto;
  } */
  /* 
  .section-padding .cl-header {
    font-size: 24px;
    margin-top: -45px;
  } */
  .top-brand-header .owl-nav {
    position: absolute;
    top: -79px;
  }
}
