.innerabout .brand-carousel {
  margin: 0 2px;
  margin-bottom: 96px;
}

.innerabout .brand-carousel .brand-wrap {
  background-color: #fff;
  min-height: 90px;
  max-height: 90px;
}

.innerabout .banner-image-carousel .brand-wrap .img-wrap {
  margin: 25px;
  width: 90px;
}

.h-section ul li {
  color: var(--cl-primary-color);
}

.innerabout .whyus-section .row {
  align-items: normal;
}

.loader-brand {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-header {
  margin-bottom: 24px;
}

.whyus-section.section-padding {
  margin-bottom: 96px;
}

.about-points {
  color: var(--cl-primary-color);
}

.about-content p {
  margin-bottom: 2rem;
}

.row-banner-section {
  margin-bottom: 96px;
}

.section-padding .banner-image-carousel .img-wrap {
  filter: grayscale(1);
}

.section-padding .banner-image-carousel .img-wrap:hover {
  filter: grayscale(0);
}

.about-page-section {
  margin-top: 48px;
}

@media screen and (max-width: 768px) {
  .cl-header .maintitle {
    margin-top: 8px;
  }

  .innerabout .brand-carousel {
    margin-bottom: 25px;
  }

  .row-banner-section {
    margin-bottom: 65px;
    margin-left: -22px;
  }

  .whyus-section.section-padding {
    margin-bottom: 110px;
  }

  .cl-header-maintitle {
    margin-bottom: -1px;
  }

  .about-section .p-header .subtitle {
    margin-left: -5px;
  }

  .about-section .p-header .maintitle {
    margin-left: -5px;
  }
}
