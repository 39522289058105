.custom-pagination .page-item .page-link {
  border: 1px solid #ddd;
  color: #111;
  height: 33px;
  line-height: 30px;
  margin-right: 10px;
  padding: 0;
  text-align: center;
  width: 33px;
}
.custom-pagination .page-item .page-link.page {
  border-radius: 0;
  background-color: transparent;
}

.custom-pagination .page-item:last-child .page-link {
  margin-right: 0;
}
.custom-pagination .page-item.active .page-link {
  background-color: transparent;
  /* color: #fff;*/
}

.custom-pagination .page-link:focus {
  box-shadow: none;
}

.page-link:active .icon {
  color: blue;
}

.icon {
  font-size: 20px;
  margin-top: -4px;
  margin-left: -7px;
  margin-right: -6px;
  color: gray;
}

.page-item:last-child .page-link {
  border-top-right-radius: none;
  border-bottom-right-radius: none;
}
