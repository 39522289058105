.whyus-section .whyus-wrap {
  border: 1px solid #ececec;
  padding: 30px;
  height: 100%;
}

.whyus-section .whyus-wrap .icon-wrap {
  align-items: center;
  display: flex;
}

.whyus-section .whyus-wrap .icon-wrap .icon {
  font-size: 50px;
}

.whyus-section .whyus-wrap .icon-wrap .sn {
  color: #eeeeee;
  font-size: 40px;
  font-weight: 800;
  margin-left: auto;
}

.whyus-section .whyus-wrap .title {
  font-size: 18px;
  margin: 20px 0;
}

/* .whyus-section .whyus-wrap .content p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
} */

.whyus-section .whyus-wrap:hover .icon-wrap .sn {
  -webkit-text-stroke: 2px var(--cl-primary-color);
  -webkit-text-fill-color: #fff;
}

.icon-wrap img {
  max-width: 20%;
}

.loader-reasonwhy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whyus-reason {
  margin-top: 96px;
}

@media screen and (max-width: 1199px) {
  .icon-wrap img {
    max-width: 27%;
  }
}

@media screen and (max-width: 991px) {
  .icon-wrap img {
    max-width: 35%;
  }
}

@media screen and (max-width: 767px) {
  .icon-wrap img {
    max-width: 30%;
  }
  
  .whyus-reason {
    margin-top: 55px;
  }
}

@media screen and (max-width: 567px) {
  .icon-wrap img {
    max-width: 18%;
  }
}

@media screen and (max-width: 367px) {
  .icon-wrap img {
    max-width: 25%;
  }
}
