.about-section .row {
  align-items: center;
}

.about-section .about-content .info {
  display: inline-flex;
}

.about-section .about-content .info li {
  display: flex;
  margin-top: 32px;
  margin-right: 30px;
  gap: 10px;
}

.about-section .about-content .info li:last-child {
  margin-right: 0;
}

.about-section .about-content .info li .img-wrap {
  margin-right: 20px;
  width: 50px;
}

.about-section .about-content .info li .text strong {
  color: var(--cl-title-color);
  display: block;
  font-size: 20px;
}

.loader-about {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-header {
  margin-bottom: 24px;
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .about-section .col-md-6.pl-50 {
    padding-left: 12.5px;
    margin-bottom: 15px;
  }

  .about-section .p-header .subtitle {
    margin-bottom: -4px;
    margin-top: 50px;
  }

  .cl-header .maintitle {
    margin-top: 32px;
  }
}

@media screen and (max-width: 576px) {
  .about-section .about-content .info {
    flex-wrap: wrap;
  }
}
