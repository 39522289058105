.getstarted-section {
  background: url("../../../../public/images/bg-image.png") no-repeat;
  background-color: #e1e6e866;
  background-position: center;
  background-size: cover;
  padding-top: 76px;
  padding-bottom: 76px;
  margin-top: 96px;
}

.started-with-danfe {
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}

.started-with-danfe .content-wrapper {
  width: 45%;
}

.started-with-danfe .content-wrapper .title {
  font-size: 36px;
}

.content a:hover {
  color: #1a68b2;
}

.getstarted-section p {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1199px) {
  .content .cl-button {
    margin-bottom: 40px;
  }

  .getstarted-section {
    padding-bottom: 96px;
    padding-top: 50px;
  }

  .started-with-danfe {
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media screen and (max-width: 991px) {
  .started-with-danfe {
    flex-wrap: wrap;
  }

  .started-with-danfe .content-wrapper {
    width: 100%;
  }

  .started-with-danfe .content-wrapper .title {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .getstarted-section {
    margin-top: -20px;
  }
}

@media screen and (max-width: 576px) {
  .started-with-danfe .content-wrapper {
    margin-left: 20px;
  }
}
