.cl-footer .footer-container {
  background-color: #252628;
  color: #fff;
  padding: 109px 0;
}

.cl-footer .logo-wrap {
  max-width: 180px;
  margin-bottom: 30px;
}

.social-icon li {
  display: inline-block;
}

.social-icon li .icon {
  color: #fff;
  font-size: 24px;
  margin-right: 15px;
}

.social-icon li:last-child .icon {
  margin-right: 0;
}

.cl-footer .footer-title,
.cl-footer li a {
  color: #fff;
}

.cl-footer .footer-title-contact,
.cl-footer li a {
  color: #fff;
}

.cl-footer .footer-title {
  font-size: 18px;
  margin-bottom: 25px;
}

.cl-footer .footer-title-contact {
  font-size: 18px;
  margin-bottom: 25px;
}

.cl-footer .footer-link li,
.footer-contact-info li {
  margin-bottom: 17px;
}

.footer-contact-info li .phone {
  display: block;
  font-size: 24px;
  font-weight: 700;
}

.footer-contact-info li {
  align-items: center;
  display: flex;
  gap: 20px;
}

.footer-contact-info li .icon {
  font-size: 20px;
}

.cl-footer .footer-bottom {
  padding: 15px 0;
}

.cl-footer .footer-bottom p {
  margin-bottom: 0;
}

.social-icon li .icon:hover {
  color: gray;
}

.footer-link ul li a:hover {
  color: gray;
}

.cl-footer .logo-wrap {
  margin-left: -10px;
}

.social-icon {
  margin-left: 5px;
}

.category-footer:hover {
  color: gray;
}
@media screen and (max-width: 992px) {
  .footer-about {
    margin-bottom: 25px;
  }

  .cl-footer .logo-wrap {
    margin-left: -11px;
  }

  .cl-footer .footer-title {
    margin-bottom: 17px;
  }

  .cl-footer .footer-title,
  .cl-footer .footer-title-contact {
    margin-top: 15px;
  }

  .cl-footer .footer-title-contact {
    margin-bottom: 17px;
  }

  .icon-link-footer {
    margin-left: 4px;
  }
}

@media screen and (max-width: 576px) {
  .footer-bottom-content {
    flex-wrap: wrap;
    gap: 15px;
  }

  .footer-bottom-content .ms-auto {
    margin-left: 0 !important;
  }

  .footer-container .row-footer {
    margin-left: -7px;
    /* margin-left: 12px; */
    line-height: 15px;
  }

  .cl-footer .logo-wrap {
    margin-left: -10px;
  }

  .footer-contact-info {
    margin-left: -3px;
    line-height: 23px;
    margin-top: 15px;
  }

  .cl-footer .footer-title-contact {
    margin-bottom: 14px;
    margin-top: 25px;
    margin-left: 3px;
  }

  .social-icon {
    margin-bottom: -12px;
    margin-top: 30px;
    margin-left: 1px;
  }

  .footer-about p {
    line-height: 26px;
  }

  .cl-footer .logo-wrap {
    margin-bottom: 22px;
  }

  .cl-footer .footer-container {
    padding: 60px 0;
  }

  .social-icon li .icon {
    margin-left: -3px;
  }

  .icon-link-footer {
    margin-left: 10px;
  }

  .help-link {
    margin-top: 25px;
  }

  .useful-link {
    margin-top: 25px;
  }

  .cl-footer .footer-title {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 367px) {
  .footer-bottom-content {
    font-size: 14px;
  }
}
