.login-form .title {
  border-bottom: 1px solid #f5f5f5;
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.login-form .form-label {
  color: var(--cl-title-color);
}

.login-form .form-control,
.login-form .form-label {
  font-weight: 500;
}

.position-relative.d-flex .form-control {
  padding: 12px 50px 12px 15px;
}

.login-form .form-control:focus {
  border-color: var(--cl-primary-color);
  color: var(--cl-primary-color);
}

.pw-reset {
  color: var(--cl-primary-color);
}

.pw-reset:hover {
  text-decoration: underline;
}

.showpassword {
  color: var(--cl-primary-color);
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  right: 0;
  margin-right: 15px;
}

.register-link p {
  text-align: center;
  margin-top: 48px;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-bottom: 96px;
}

.register-link a {
  color: rgba(26, 104, 178, 1);
}

.have-account {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  gap: 7px;
  padding-bottom: 96px;
}

.have-account a {
  color: #1a68b2;
}

.login-form .cl-button:hover {
  color: var(--cl-primary-color);
}

.register-form .cl-button:hover {
  color: var(--cl-primary-color);
}

.login-password {
  padding-bottom: 10px;
}

.register-agree-terms {
  padding-bottom: 30px;
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-dark);
}

.login-forget-password p {
  cursor: pointer;
}

.login-forget-password {
  margin-bottom: 25px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.forget-button {
  /* color: #1a68b2; */
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.popup-row-reset {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forget-password-btn {
  background-color: #1a68b2;
  border: 1px solid #1a68b2;
}

.forget-password-btn:hover {
  background-color: transparent;
  color: #1a68b2;
  transition: all ease-in-out 0.4s;
}

@media screen and (max-width: 575px) {
  .form-control-register {
    margin-left: 25px;
    margin-right: 25px;
  }

  .form-control-login {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 350px) {
  .form-control-register {
    margin-left: 12px;
    margin-right: 12px;
  }

  .form-control-login {
    margin-left: 12px;
    margin-right: 12px;
  }

  .register-link p {
    /* font-size: 15px; */
    gap: 3px;
  }

  /* .have-account {
    font-size: 15px;
  } */
}
