.custom-tabs .react-tabs__tab-list {
  border: none;
  margin-bottom: 30px;
  text-align: center;
}

.custom-tabs .react-tabs__tab {
  border: none;
  border-bottom: 1px solid transparent;
  font-size: 18px;
  font-weight: 500;
  position: static;
}

.custom-tabs .react-tabs__tab--selected {
  border: none;
  border-bottom: 1px solid var(--cl-primary-color);
  border-radius: 0;
  color: var(--cl-primary-color);
}

.border-wrap {
  border: 1px solid #e9e9e9;
  padding: 50px 30px;
}

.profile-section .profile-wrap {
  display: flex;
  gap: 50px;
}

.profile-wrap .img-wrap {
  position: relative;
  height: 100%;
  width: 155px;
}

.profile-wrap .img-wrap .icon-wrap {
  background-color: var(--cl-primary-color);
  border-radius: 100%;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  height: 32px;
  line-height: 30px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 32px;
}

.profile-wrap .edit-profile {
  width: 100%;
}

.react-tabs__tab {
  margin-left: 24px;
}

.first-letter-profile {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background-color: green;
  text-align: center;
  padding-top: 25px;
}

.first-letter-profile h1 {
  color: white;
}

#profile-image {
  border-radius: 100%;
  background-color: green;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.form-label {
  color: var(--cl-title-color);
}

.form-control {
  color: var(--cl-text-color);
}

.edit-form .cl-button:hover {
  color: var(--cl-primary-color);
}
@media screen and (max-width: 1399px) {
  .profile-section .profile-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .profile-wrap .img-wrap .icon-wrap {
    position: absolute;
    right: 56px;
  }
}
@media screen and (max-width: 1199px) {
  .profile-section .profile-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .profile-wrap .img-wrap .icon-wrap {
    position: absolute;
    right: 97px;
  }
  #profile-image {
    border-radius: 100%;
    background-color: green;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .profile-wrap .img-wrap {
    position: relative;
    height: 100%;
    width: 200px;
  }

  .form-col-changepassword {
    width: 55%;
  }
}

@media screen and (max-width: 991px) {
  .profile-section .profile-wrap {
    display: block;
  }

  .profile-wrap .img-wrap {
    width: 65px;
    margin-bottom: 30px;
  }

  .profile-wrap .img-wrap .icon-wrap {
    font-size: 14px;
    height: 24px;
    line-height: 21px;
    width: 24px;
  }

  #profile-image {
    border-radius: 100%;
    background-color: green;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .profile-wrap .img-wrap {
    position: relative;
    height: 100%;
    width: 200px;
  }

  .profile-wrap .img-wrap .icon-wrap {
    border-radius: 100%;
    bottom: 3px;
    color: #fff;
    font-size: 16px;
    height: 32px;
    line-height: 30px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 32px;
    left: 70px;
  }

  .form-col-changepassword {
    width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .form-col-changepassword {
    width: 100%;
  }
}

@media screen and (max-width: 567px) {
  /* .custom-tabs .react-tabs__tab {
    /* display: block;
    text-align: left; 
    overflow-x: auto;
  }

  .custom-tabs .react-tabs__tab {
    font-size: 16px;
  } */

  .custom {
    flex-wrap: nowrap;
  }

  .custom {
    white-space: nowrap;
  }

  .react-tabs__tab {
    margin-left: 30px;
  }
}

@media screen and (max-width: 369px) {
  .cancle-button {
    width: 70px;
    font-size: 15px;
  }

  .cl-button-change {
    font-size: 15px;
    width: 122px;
    padding: 10px 1px;
  }
}
