.product-carousel .product-wrap,
.allproduct-section .product-wrap {
  border: 1px solid #e9e9e9;
  padding: 20px;
  margin-top: 16px;
}

.product-wrap .img-product {
  margin: 0 auto 30px;
  overflow: hidden;
  position: relative;
  max-height: 180px;
  min-height: 180px;
  object-fit: cover;
}

.product-wrap .img-product .tag {
  left: 0;
  position: absolute;
  z-index: 1;
}

.product-wrap .img-product img {
  transition: all ease-in-out 0.4s;
  height: 184px;
  width: 184px;
  object-fit: contain;
}

.product-wrap .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-top: 30px;
}

.product-wrap .price-tag {
  color: var(--cl-primary-color);
  display: block;
  font-weight: 500;
}

.product-wrap:hover img {
  transform: scale(1.1);
  transition: all ease-in-out 0.4s;
}

.product-wrap:hover .title {
  color: var(--cl-primary-color);
}

.product-wrap:hover .price-tag {
  color: var(--cl-title-color);
}

/* all product css */
.allproduct-section .box {
  background-color: #fafafa;
  margin-bottom: 15px;
  padding: 30px;
}

.allproduct-section .box .title {
  font-size: 18px;
  margin-bottom: 20px;
}

.allproduct-section .box ul li {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  cursor: pointer;
}

.allproduct-section .box ul li:last-child {
  margin-bottom: 0;
}

.allproduct-section .search-bar {
  background-color: #fafafa;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: -13px;
  margin-right: -13px;
}

.search-bar .form-group .form-control {
  background-color: #fff;
  border: 1px solid #cccccc;
}

.search-bar .icon-wrap {
  background-color: var(--cl-primary-color);
}

.search-bar .sorting-wrap {
  margin-left: auto;
  align-items: center;
  width: 40%;
}

.search-bar .sorting-wrap strong {
  font-size: 14px;
  margin: 0 0px;
}

.search-bar .sorting-wrap .icon {
  color: var(--cl-primary-color);
  font-size: 18px;
  margin-left: 20px;
}

.search-bar .form-group .form-control-product,
.search-bar .form-control-product,
.search-bar .icon-wrap {
  padding: 8px 15px;
}

.search-bar .form-control-product {
  border: 1px solid #cccccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* .bg-product {
  position: relative;
  padding: 30px;
  z-index: 1;
} */

/* .bg-product:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  height: 234px;
  position: absolute;
  top: 0;
  width: 234px;
  z-index: -1;
  object-fit: contain;
} */

/* .bg-product .title {
  color: #fff;
  font-size: 22px;
}

.bg-product a {
  font-size: 14px;
  color: #fff;
} */

.allproduct-section .product-wrap .title {
  font-weight: 500;
}

.allproduct-section .product-wrap .morebtn {
  color: var(--cl-primary-color);
}

.custom-pagination .page-item .page-link {
  border: 1px solid #ddd;
  color: #666;
  height: 32px;
  margin-right: 10px;
  padding: 0;
  text-align: center;
  width: 32px;
}

.custom-pagination .page-item:last-child .page-link {
  margin-right: 0;
}

.custom-pagination .page-item.active .page-link {
  background-color: transparent;
}

.custom-pagination .page-link:focus {
  box-shadow: none;
}

/* product detail css */
.product-zoom-content .title {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.product-zoom-content .status {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.product-zoom-content li.status {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 5px;
}

.product-zoom-content li.status:last-child {
  margin-bottom: 0;
}

.product-zoom-content .status span {
  color: var(--cl-green-color);
  font-weight: 500;
}

.product-zoom-content .butn-wrap {
  margin-bottom: 30px;
}

.product-zoom-content .butn-wrap .cl-button {
  margin-right: 10px;
}

.b-bottom {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 48px;
  padding-bottom: 50px;
}

.productdetail-section .desc-wrap .cl-header .cl-title {
  border-bottom: 1.5px solid var(--cl-primary-color);
  color: var(--cl-primary-color);
  display: inline-block;
  font-size: 18px;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.thumbnail-slider .item img {
  width: 80px;
  height: 80px;
  border: 1px solid #ddd;
  margin: 0 auto;
  padding: 6px;
}

.icon-wrap .icons {
  color: white;
}

.product-zoom-wrap {
  display: flex;
  gap: 50px;
}

.product-zoom-wrap .w-full {
  order: 12;
  width: 72%;
}

.product-zoom-wrap .slick-vertical {
  width: 20%;
}

.loader-product {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-products {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 50px;
  /* position: absolute;
  bottom: -175px;
  right: 40%; */
}

.loader-similarProduct {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-array {
  position: relative;
  margin-bottom: 48px;
}

.link-popup {
  color: #1a68b2;
}

.product-carousel .product-wrap img {
  width: 100%;
  height: 190px;
  object-fit: contain;
  padding: 10px;
}
/* .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: -65px;
  right: 0;
} */
.product-carousel.owl-theme .owl-nav [class*="owl-"] {
  background-color: #f3f3f3 !important;
  /* padding: 15px 15px; */
  /* box-shadow: 0 1px 5px rgb(0 0 0 / 14%); */

  position: absolute;
  text-align: center;
  top: -77px;
  width: 32px;
  height: 32px;
  right: 0;
  margin: 0;
}

.product-carousel.owl-theme .owl-nav .owl-prev {
  right: 45px;
}

.product-carousel.owl-theme .owl-nav [class*="owl-"] span {
  font-size: 32px;
  line-height: 25px;
}

.morebtn {
  margin-top: 24px;
}

.custom-pagination .page-item .page-link.pagination {
  border: 0;
}

.section-padding .form-control-product {
  width: 331px;
}

.select-icon {
  font-size: 50px;
  position: relative;
  right: 25px;
  top: 2px;
}

.productdetail-section .butn-wrap .cl-button-dark:hover {
  color: var(--cl-primary-color);
}

.productdetail-section .butn-wrap .cl-button:hover {
  color: var(--cl-primary-color);
}

.icon {
  font-size: 20px;
  margin-top: -4px;
  margin-left: -7px;
  margin-right: -6px;
  color: gray;
}

.dropdown-category-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  justify-content: space-between;
}

#categoryDropdown {
  padding: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
}

#brandDropdown {
  padding: 8px;
  display: flex;
  gap: 32px;
  align-items: center;
  font-size: 14px;
}

.dropdown-item {
  font-size: 13px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.product-wrap .title {
  cursor: pointer;
}

.checkbox-brand {
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 15px;
}

.checkbox-brand-dropdown {
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 5px;
  margin-left: 10px;
}

.checkbox-brand input {
  accent-color: rgb(26, 104, 178);
}

/* input[type="checkbox"] {

} */

.no-more-product {
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
}

@media screen and (max-width: 1199px) {
  .section-padding .form-control-product {
    width: 285px;
  }

  .search-bar .form-group .form-control-product,
  .search-bar .form-control-product,
  .search-bar .icon-wrap {
    padding: 8px 15px;
  }

  .section-padding .form-control {
    padding: 9px 16px;
  }

  .allproduct-section .search-bar {
    margin-left: -12px;
    margin-right: -12px;
  }
}
@media screen and (max-width: 991px) {
  .productdetail-section .cl-button-dark {
    margin-top: 15px;
  }

  .allproduct-section .search-bar .form-group,
  .search-bar .sorting-wrap {
    margin-left: 0;
    width: 100%;
  }

  .search-bar .sorting-wrap {
    margin-left: 9px;
  }

  .section-padding .form-control-product {
    width: 240px;
  }

  .search-bar .form-group .form-control-product,
  .search-bar .form-control-product,
  .search-bar .icon-wrap {
    padding: 8px 15px;
  }

  .section-padding .form-control-product {
    padding: 9px 3px;
  }
}

@media screen and (max-width: 767px) {
  /* .bg-product {
    margin-bottom: 15px;
  } */

  .search-bar .form-group {
    margin: 0;
    width: 100%;
  }

  .search-bar .sorting-wrap {
    width: 100%;
  }

  .allproduct-section .top-product-header .form-group {
    position: static;
  }

  .section-padding .form-control-product {
    width: 290px;
  }

  .search-bar .form-group .form-control-product,
  .search-bar .form-control-product,
  .search-bar .icon-wrap {
    padding: 8px 15px;
  }

  .allproduct-section .search-bar {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .product-wrap .img-product {
    max-height: 300px;
  }

  .product-wrap .img-product img {
    width: 100%;
  }

  .product-carousel.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    text-align: center;
    top: -68px;
  }

  .allproduct-section .product-wrap {
    margin-top: 0px;
  }
}

@media screen and (max-width: 567px) {
  .allproduct-section .search-bar {
    flex-direction: column;
  }

  .section-padding .form-control-product {
    width: 290px;
  }

  .search-bar .sorting-wrap {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }

  .section-padding .form-control-product {
    width: 95%;
  }

  .thumbnail-slider .item img {
    object-fit: cover;
  }

  .product-row {
    --bs-gutter-x: -0.5rem;
  }

  .allproduct-section .form-control-view {
    visibility: hidden;
    display: none;
  }

  .allproduct-section .text-name {
    display: none;
  }

  .allproduct-section .search-bar {
    padding-top: 18px;
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 410px) {
  .product-zoom-content .butn-wrap .cl-button {
    margin-bottom: 10px;
  }

  .product-wrap .img-product {
    max-height: 180px;
    min-height: 140px;
  }

  .product-carousel .product-wrap .img-wrap {
    max-height: 180px;
    min-height: 140px;
  }

  .no-more-product {
    font-size: 19px;
  }
}

@media screen and (max-width: 336px) {
  .dropdown-category-brand {
    gap: 1px;
  }

  }
#brandDropdown {
    padding: 8px;
    display: flex;
    gap: 20px
}
