.enquirylist-section .product-list {
  justify-content: space-between;
}

.enquirylist-section .product-list .product {
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}

.enquirylist-section .product-list .product h6 {
  margin: 0;
}

.enquirylist-section .product-list img {
  width: 45px;
}

.enquirylist-section .product-list .title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 20px;
}

.enquirylist-section .product-list .status {
  color: var(--cl-green-color);
  margin-right: 100px;
}

.enquirylist-section .product-list .action {
  align-items: center;
  display: flex;
}

.enquirylist-section .product-list .action .icon {
  color: var(--cl-primary-color);
  cursor: pointer;
  font-size: 20px;
}

.btn-enquirylist.cl-button-dark {
  display: flex;
  justify-content: space-between;
}

.no-product-added {
  font-size: 20px;
  font-weight: bold;
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*Enquiry History*/
.custom-enquiry-tabs .react-tabs__tab-list {
  border: none;
  margin-bottom: 30px;
  text-align: center;
}

.custom-enquiry-tabs .react-tabs__tab {
  border: none;
  border-bottom: 1px solid transparent;
  font-size: 18px;
  font-weight: 500;
  position: static;
}

.custom-enquiry-tabs .react-tabs__tab--selected {
  border: none;
  border-bottom: 1px solid var(--cl-primary-color);
  border-radius: 0;
  color: var(--cl-primary-color);
}

.enquiryhistory-section .product-history {
  justify-content: space-between;
}

.enquiryhistory-section .product-history .product {
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}

.enquiryhistory-section .product-history .product h6 {
  margin: 0;
}

.enquiryhistory-section .product-history img {
  width: 45px;
}

.enquiryhistory-section .product-history .title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 20px;
}

.enquiryhistory-section .product-history .status {
  color: var(--cl-green-color);
  /* margin-right: 100px; */
}

.enquiryhistory-section .product-history .action-history {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  gap: 25px;
}

.enquiryhistory-section .product-history .action-history.icon {
  color: var(--cl-primary-color);
  cursor: pointer;
  font-size: 20px;
}

.product-enquiry-history {
  display: flex;
  /* justify-content: space-between; */
  gap: 240px;
  align-items: center;
  margin-left: 25px;
}

.product-list.img-warp {
  width: 50px;
  height: 50px;
}

.producthistory-image {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
}

.enquiryproduct-history-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.producthistory-image img {
  margin-bottom: 10px;
}

.enquirylist-section .cl-button-dark:hover {
  color: var(--cl-primary-color);
}

.enquirylist-section .cl-button:hover {
  color: var(--cl-primary-color);
}
/* .history-product-details {
  display: flex;
  /* justify-content: space-between;
} */

.icon-left {
  font-size: 20px;
  margin-top: -4px;
  margin-left: -7px;
  margin-right: 8px;
  color: gray;
}

.enquirylist-section .icon-left:hover {
  color: #1a68b2;
}

.no-history {
  font-size: 20px;
  font-weight: bold;
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-item:first-of-type .accordion-button {
  width: 867px;
}

 .action-history .status-creatat{
    margin-left: 200px;
 }

@media screen and (max-width: 1199px) {
  .product-enquiry-history {
    display: flex;
    align-items: center;
    gap: 119px;
  }

  .enquiryhistory-section .product-history .action-history {
    align-items: center;
    display: flex;
  }

  .enquiryproduct-history-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion-item:first-of-type .accordion-button {
    width: 720px;
  }

  .action-history .status-creatat{
    margin-left: 100px;
  }
}

@media screen and (max-width: 991px) {
  .enquiryhistory-section .product-history .action-history {
    align-items: center;
    display: flex;
  }

  .product-enquiry-history {
    display: flex;
    align-items: center;
    gap: 190px;
  }

  .enquiryproduct-history-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion-item:first-of-type .accordion-button {
    width: 637px;
  }
}

@media screen and (max-width: 767px) {
  .product-enquiry-history {
    display: flex;
    align-items: center;
    gap: 64px;
  }

  .enquiryhistory-section .product-history .action-history {
    align-items: center;
    display: flex;

    gap: 70px;
  }

  .enquiryproduct-history-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion-item:first-of-type .accordion-button {
    width: 457px;
  }

   .action-history .status-creatat{
    margin-left: 0px;
  }
}

/* @media screen and (max-width: 567px) {
  .enquiryhistory-section .product-history .action-history {
    align-items: center;
    display: flex;
   
  }

  .product-enquiry-history {
    display: flex;
    align-items: center;
    gap: 64px;
  }
} */

@media screen and (max-width: 567px) {
  .enquiryhistory-section .product-history .action-history {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    gap: 10px;
  }

  .product-enquiry-history {
    display: flex;
    align-items: center;
    gap: 86px;
  }

  .enquiryhistory-section .product-history .status {
    margin-bottom: 9px;
  }

  .enquiryhistory-section .product-history .status {
    /* margin-right: 100px; */
    font-size: 14px;
  }

  .product-enquiry-history h6 {
    font-size: 16px;
  }

  .enquiryproduct-history-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion-item:first-of-type .accordion-button {
    width: 447px;
  }
}

@media screen and (max-width: 528px) {
  .accordion-item:first-of-type .accordion-button {
    width: 422px;
  }
}

@media screen and (max-width: 491px) {
  .accordion-item:first-of-type .accordion-button {
    width: 100%;
  }
}

@media screen and (max-width: 415px) {
  .product-enquiry-history {
    display: flex;
    align-items: center;
    gap: 65px;
    font-size: 15px;
    margin-left: 10px;
  }

  .product-enquiry-history h6 {
    font-size: 16px;
  }
}

@media screen and (max-width: 367px) {
  .enquirylist-section .cl-button-dark {
    font-size: 16px;
    padding: 12px 8px;
  }

  .enquirylist-section .cl-button {
    font-size: 16px;
    padding: 12px 8px;
  }
}
