.shop-section .shop-wrap {
  align-items: center;
  /* background-color: #dce4e5; */
  background: linear-gradient(to left, #8ed613, #3a9c3b);
  border-radius: 4px;
  display: flex;
  gap: 15px;
  height: 100%;
}

.shop {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .left-wrap {
  padding: 57px 40px;
} */
.shop-section .shop-wrap .content {
  width: 78%;
  padding: 79px 49px;
}

.shop-section .content .title {
  margin-bottom: 16px;
  color: white;
}

.shop-section .content-wrapped p {
  margin-bottom: 2rem;
  color: white;
}

.shop-section .content-title p {
  margin-bottom: 2rem;
  color: white;
}

.shop-section .shop-wrap .img-wrap,
.shop-section .shop-wrap.right-wrap .content {
  order: 1;
}

.shop-section .shop-wrap.right-wrap {
  background: linear-gradient(to left, #df3ca3, #7f336b);
  position: relative;
  z-index: 9;
}

.shop-section .shop-wrap.right-wrap .img-wrap {
  order: 12;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 265px;
  z-index: -9;
}

.shop-section .shop-wrap.right-wrap .image-wrapped {
  order: 12;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 265px;
  z-index: -9;
}

.shop-section .shop-wrap.right-wrap .content {
  padding: 56px 48px;
}

.shop-section .shop-wrap.right-wrap .content-wrapped {
  padding: 56px 48px;
  margin-top: -49px;
}

.icon-shop {
  font-size: 20px;
  margin-top: -4px;
  margin-left: 3px;
  margin-right: -6px;
  color: white;
}

.shop-section .shop-now-link-right:hover {
  color: lightgreen;
}

.shop-now-link-right {
  color: white;
}

.shop-section .shop-now-link:hover {
  color: lightpink;
}

.shop-now-link {
  color: white;
}

.icon-shop-left {
  margin-left: 5px;
  margin-top: -1px;
}

.icon-shop-right {
  margin-left: 5px;
  margin-top: -1px;
}

.shop-now-link-right:hover .icon-shop-right {
  margin-left: 10px;
  transition: all ease-in-out 0.4s;
}

.shop-now-link:hover .icon-shop-left  {
  margin-left: 10px;
  transition: all ease-in-out 0.4s;
}

@media screen and (max-width: 1199px) {
  .shop-section .shop-wrap.right-wrap .content-wrapped {
    margin-top: -60px;
  }

  .shop-section .shop-wrap.right-wrap .image-wrapped {
    width: 246px;
  }
}

@media screen and (max-width: 991px) {
.shop-section .shop-wrap.right-wrap .content-wrapped {
    margin-top: -221px;
}

  .shop-section .content-wrapped p {
    width: 188px;
  }

  .shop-section .shop-wrap .content-title {
    margin-top: -5px;
  }

  .shop-section .shop-wrap.right-wrap .image-wrapped {
    width: 264px;
  }

  .shop-section-wrap .shop-image {
    margin-left: 45px;
  }

  .shop-section .shop-wrap .content-title {
    width: 78%;
    padding: 0px 49px;
  }

  .shop-section .shop-wrap .content-title {
    margin-top: 70px;
  }

  .shop-section .shop-wrap {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .shop-section .row {
    gap: 15px;
  }

  .shop-section .shop-wrap.right-wrap .image-wrapped {
    width: 300px;
  }

  .shop-section .shop-wrap {
    flex-wrap: wrap;
  }

  .shop-wrap .shop-image {
    margin-left: 39px;
    margin-top: -86px;
  }

  .shop-section .shop-wrap .content-title {
    padding: 15px 49px;
  }

  .shop-section .content-title p {
    margin-bottom: 3rem;
  }

  .shop-section .content .title {
    margin-top: 50px;
  }

  .shop-section .content-title p {
    margin-bottom: 1rem;
  }

  .shop-section .shop-wrap.right-wrap .content-wrapped {
    padding: 177px 48px;
    margin-top: -145px;
  }

  .shop-section .shop-wrap .content-title {
    margin-top: 10px;
  }
}

@media screen and (max-width: 576px) {
  .shop-section .shop-wrap .img-wrap,
  .shop-section .shop-wrap.right-wrap .content {
    order: inherit;
  }

  /* .shop-section .shop-wrap {
    flex-wrap: wrap;
  } */

  .shop-section .shop-wrap .content {
    width: 100%;
  }

  .shop-section .shop-wrap .content-title {
    margin-top: 33px;
  }

  .shop-section .shop-wrap.right-wrap .content {
    text-align: left;
  }

  .shop-section .shop-wrap .shop-image {
    margin-left: 37px;
  }

  .shop-section .shop-wrap.right-wrap .image-wrapped {
    width: 250px;
  }

  .shop-section .content .title {
    margin-top: 13px;
  }

  .shop-section .shop-wrap.right-wrap .content-wrapped {
    padding: 46px 48px;
    margin-top: 2px;
  }
}

@media screen and (max-width: 450px) {
  .shop-section .shop-wrap.right-wrap .content-wrapped {
    padding: 46px 48px;
    margin-top: 2px;
  }
  .shop-section .shop-wrap.right-wrap .image-wrapped {
    width: 185px;
  }
}

@media screen and (max-width: 350px) {
  .shop-section .shop-wrap.right-wrap .content-wrapped {
    padding: 75px 48px;
    margin-top: -30px;
  }
  .shop-section .shop-wrap.right-wrap .image-wrapped {
    width: 170px;
  }
}
