* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --cl-font-family: "Mulish", sans-serif;
  --cl-primary-color: #1a68b2;
  --cl-bg-color: #f8f8f8;
  --cl-green-color: #059669;
  --cl-text-color: #666666;
  --cl-title-color: #111111;
}

body {
  font-family: var(--cl-font-family);
  font-size: 16px;
  margin: 0 auto;
  overflow-x: hidden;
  color: var(--cl-text-color);
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--cl-font-family);
  color: var(--cl-title-color);
  font-weight: 700;
}

ul,
ol {
  padding-left: 0;
  margin-bottom: 0;
}

ul li {
  list-style: none;
}

a {
  color: var(--cl-title-color);
  list-style-type: none;
  transition: all ease-in-out 0.4s;
  text-decoration: none;
}

a:hover {
  color: var(--cl-primary-color);
  transition: all ease-in-out 0.4s;
  text-decoration: none;
}

img {
  max-width: 100%;
  width: 100%;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.p-0 {
  padding: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

button:focus,
input:focus,
.form-control:focus,
.form-check-input:focus,
input:focus {
  border-color: transparent;
  box-shadow: none;
}

.form-control {
  /* color: var(--cl-primary-color); */
  padding: 12px 15px;
}

.form-control:focus {
  border-color: var(--cl-primary-color);
  color: var(--cl-primary-color);
}

.cl-button,
.cl-button-dark {
  align-items: center;
  background: var(--cl-primary-color);
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 16px;
  justify-content: center;
  padding: 12px 32px;
  color: white;
}

.cl-button-dark {
  background-color: #e5e5e5;
  color: var(--cl-title-color);
  font-weight: 600;
}

.cl-button .icon {
  font-size: 20px;
  margin-left: 8px;
  transition: all ease-in-out 0.4s;
}

.cl-button:hover,
.cl-button-dark:hover {
  background: transparent;
  border: 1px solid var(--cl-primary-color);
  color: var(--cl-title-color);
}

.morebtn {
  /* color: var(--cl-text-color); */
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.morebtn:hover {
  color: var(--cl-primary-color);
}

.morebtn:hover .icon-shop {
  margin-left: 10px;
  transition: all ease-in-out 0.4s;
}

.cl-header {
  margin-bottom: 48px;
}

.cl-header .maintitle,
.cl-header .title {
  color: var(--cl-title-color);
  font-family: var(--cl-font-family);
  font-size: 36px;
  font-weight: 700;
  /* margin-bottom: 15px; */
}

.cl-header .title {
  font-size: 24px;
}

.cl-header .subtitle {
  color: var(--cl-primary-color);
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.95px;
}

.section-padding {
  padding-top: 96px;
}
/* .section-padding {
  padding-top: 48px;
} */

.italic {
  font-style: italic;
}

.tag {
  background-color: var(--cl-primary-color);
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  padding: 2px 8px;
}

.text-right {
  text-align: right;
}

html {
  scroll-behavior: smooth;
}
/* .cl-footer {
  margin-top: 96px;
} */
@media screen and (max-width: 991px) {
  .cl-header .maintitle {
    font-size: 26px;
  }

  .cl-header .subtitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .section-padding {
    padding-top: 55px;
  }

  .cl-header {
    margin-bottom: 38px;
  }
}
