.client-carousel .img-wrap {
  align-items: center;
  display: flex;
  filter: grayscale(1);
  height: 90px;
  margin: 0 auto;
  width: 90px;
  cursor: pointer;
}

.client-carousel .img-wrap:hover {
  filter: grayscale(0);
}

.loader-client {
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-carousel .owl-item img {
  display: block;
  width: 91px;
  height: 90px;
}
